import { useContext } from "react";
import { AuthContext } from "../../hooks/AuthContext";
import { CircularProgress } from "@mui/material";
import React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import { Route, Routes } from "react-router-dom";
import SignIn from "../Auth/SignIn/SignIn";
import CompleteRegistration from "../Auth/CompleteRegistration/CompleteRegistration";
import NavBarMenu from "./NavBarMenu";
import Banner from "../Banner/Banner";

function Navigator() {
    const { currentUser, isInitializingCurrentUser } = useContext(AuthContext);
    if (isInitializingCurrentUser) {
        return <CircularProgress />;
    }
    if (currentUser) {
        return (
            <React.Fragment>
                <Banner />
                <NavigationBar />
                <NavBarMenu />
            </React.Fragment>
        );
    } else {
        return (
            <Routes>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Route index={true} element={<SignIn />} />
                <Route path="/SignIn" element={<SignIn />} />
                {/*<Route path="/ForgotPassword" element={<ForgotPassword/>}/>*/}
                {/*<Route path="/ResetPassword" element={<ResetPassword/>}/>*/}
                <Route
                    path="/Registration"
                    element={<CompleteRegistration />}
                />
                <Route path="*" element={<SignIn />} />
            </Routes>
        );
    }
}

export default Navigator;
